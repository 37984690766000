.a {
  display: block;
  width: 100px;
  height: 1px;
  padding: 10px;
  border: 1px;

  .c {
    height: 5px;
  }
}
